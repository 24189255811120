// Packages
import React, { useContext, useState, useEffect } from "react";
import { Link as RouterLink, useLocation, useSearchParams, useNavigate} from "react-router-dom";

// Styles
import { useStyles } from "./styles";

// MUI Components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from "@material-ui/core/Link";

// Context
import MeContext from "contexts/MeContext";

// Components
import Badge from "components/Badge";

// Data
import ROLES from "enums/ROLES";

// Services
import * as userUtil from "util/userUtil";
import * as LandingPageApi from "apis/LandingPageApi";

export default function Topbar({signOut, setProductData}) {
  const classes = useStyles();
  const location = useLocation();
  const me = useContext(MeContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParam = searchParams.get('q');
  const [landingPages, setLandingPages] = useState();
  
  if(queryParam){
    setProductData(queryParam);
  }
  
  useEffect(() => {
    async function fetchLandingPages() {
      const tempLandingPages = await LandingPageApi.getAll();
      setLandingPages(tempLandingPages);
    }
    fetchLandingPages();   
  }, []);

  function setProduct(product){
    setProductData(product);
    //window.location.href = window.location.origin + `/#/product?q=${product}`;
  }
  function loadProduct(product){
    setProductData(product);
    //window.location.href = window.location.origin + `/#/product?q=${product}`;
    navigate("/product");
  }

  return (
    <div id="topbar-root" className={classes.root}>
      <div id="topbar-menu" className={classes.topMenuBar}>
        <div id="header" className={classes.header}>
          <Link id="link-img" href="/#" rel="noreffer" className={classes.menuButton}>
            <img src ="/AAIS_Blue.svg" alt="logo" name="logo" className={classes.logo}/>
          </Link>
        </div>
        <div id="menuItems">
          <List id="menuItemsList" className={classes.menuItems}>
            <ListItem 
              id="menuItemsListItem-home"
              className={classes.item}
            >
              <Link
                id="menuItem-home"
                className={location.pathname === '/#' ? `${classes.home} ${classes.active}` : classes.home}
                rel="noreferrer"
                href={`/#`}
              >
                Home
              </Link>
            </ListItem>
            <ListItem 
              id="menuItemsListItem-product"
              className={location.pathname === '/#/browse' ? `${classes.item} ${classes.active}` : classes.item}
            >
              Products
              <ul id="menuItemsList-productsUL" className={classes.dropdown}>
                <li id="menuItemsList-searchLI" className={classes.dropdownItem}>
                  <Link
                    id="menuItem-search"
                    className={classes.dropdownLink}
                    rel="noreferrer"
                    href={`/#/browse`}
                  >
                    Search
                  </Link>
                </li>

                {landingPages && (landingPages.map((landingPage) => {
                  return (<li id={`menuItemsList-${landingPage.keyName}LI`} className={classes.dropdownItem} key={`menuItemsList-${landingPage.keyName}LI`}>
                  {location.pathname !== '/product' && (<span
                    id={`menuItem-${landingPage.keyName}`}
                    className={classes.dropdownLink}
                    rel="noreferrer"
                    onClick={() => loadProduct(landingPage.keyName)}
                    href={'/#/product'}
                  >
                    {landingPage.name}
                  </span>)}
                  {location.pathname === '/product' && (
                  <span
                    id="menuItem-auto-button"
                    className={classes.dropdownLink}
                    onClick={() => setProduct(landingPage.keyName)}
                  >
                    {landingPage.name}
                  </span>)}
                </li>);
                }))}
              </ul>
            </ListItem>
            <ListItem
              id="menuItemsListItem-services"
              className={classes.item}
            >
              Services
              <ul id="menuItemsList-servicesUL" className={classes.dropdown}>
                {/* <li className={classes.dropdownItem}>
                  <Link
                    className={classes.dropdownLink}
                    rel="noreferrer"
                    href={`/#/risk`}
                  >
                    Cat Peril Resource Center
                  </Link>
                </li> */}
                <li id="menuItemsList-servicesUL-statreporting" className={classes.dropdownItem}>
                  <Link
                    className={classes.dropdownLink}
                    rel="noreferrer"
                    href={`/#/data`}
                  >
                    Statistical Reporting
                  </Link>
                </li>
              </ul>
            </ListItem>
          </List>
        </div>
        <div id="div-badge" className={classes.badge}>
          <List id="list-badge">
            <ListItem id="list-item-badge" className={classes.badgeItem}>
              <Badge/>
              <ul id="ul-profile" className={classes.dropdown}>
                <li id="li-profile" className={classes.dropdownItem}>
                  <Link
                    id="link-profile"
                    className={classes.dropdownLink}
                    rel="noreferrer"
                    href={`/#/users/${me.userId}`}
                  >
                    My Profile
                  </Link>
                </li>
                <li id="li-orgs" className={classes.dropdownItem}>
                  <Link
                    id="link-orgs"
                    className={classes.dropdownLink}
                    rel="noreferrer"
                    href={`/#/orgs`}
                  >
                    My Organizations
                  </Link>
                </li>
                {userUtil.isAdmin(me) && (
                  <li id="li-employees" className={classes.dropdownItem}>
                    <Link
                      id="link-employees"
                      className={classes.dropdownLink}
                      rel="noreferrer"
                      href={`/#/employees`}
                    >
                      Employees
                    </Link>
                  </li>
                )}
                {/* <li className={classes.dropdownItem}>All Users</li> */}
                <li id="li-faq" className={classes.dropdownItem}>
                  <Link
                    id="link-employees"
                    className={classes.dropdownLink}
                    rel="noreferrer"
                    href={`/#/faq`}
                  >
                    FAQ
                  </Link>
                </li>
                {/* <li className={classes.dropdownItem}>Employees</li> */}
                {userUtil.isAdmin(me) && (
                  <li id="li-users" className={classes.dropdownItem}>
                    <Link
                      id="link-users"
                      className={classes.dropdownLink}
                      rel="noreferrer"
                      href={`/#/users`}
                    >
                      All Users
                    </Link>
                  </li>
                )}
                <li
                  id="li-sign-out"
                  className={classes.dropdownItem}
                  onClick={(event) => {
                    event.preventDefault();
                    signOut();
                }}
                >
                  Sign Out
                </li>
              </ul>
            </ListItem>
          </List>
        </div>
      </div>
    </div>
  );
}