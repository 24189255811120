// Packages
import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

// MUI Components
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

// MUI Icons
import SearchIcon from "@material-ui/icons/Search";

// Components
import MyRecentProductsCard from "components/MyRecentProductsCard";
import MyOrgsCard from "components/MyOrgsCard";
import DefaultLinkInfoCard from "components/DefaultLinkInfoCard";
import QuickLinksCard from "components/QuickLinksCard";
import NewlyAvailableCard from "components/NewlyAvailableCard";

// Styles
import { useStyles } from "./styles";

// Util
import * as userUtil from "util/userUtil";

// Context
import MeContext from "contexts/MeContext";

// Drag and Drop
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function HomeScreen(props) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const me = useContext(MeContext);
  const location = useLocation();

  useEffect(() => {
    console.log("home screen loaded", location);
    window.gtag("event", "page_view", {
      page_title: "Home Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
      send_to: `${process.env.REACT_APP_GA}`
    });
  }, [location]);

  function getTitle() {
    if (me && me.firstName) return `Welcome, ${me.firstName}`;
    else if (me && me.email) return `Welcome, ${me.email}`;
    return `Welcome`;
  }

  function handlekeyDown(e) {
    if (e.key === "Enter") {
      const prefix = `#/browse?`;
      if (searchValue) window.location = `${prefix}q=${encodeURI(searchValue)}`;
      else window.location = prefix;
      setSearchValue("");
    }
  }

  const [cards, setCards] = useState([
    { id: 'browse', type: 'link', title: 'Browse All Products', href: '/#/browse', verbiage: 'Browse All AAIS Products' },
    // { id: 'risk', type: 'link', title: 'Risk Awareness Service', href: '#/risk', verbiage: 'Wildfire Resource Center' },
    // { id: 'data', type: 'link', title: 'Statistical Reporting', href: '#/data', verbiage: 'Statistical Reporting (BETA)' },
    // { id: 'quicklinks', type: 'quicklinks'},
    { id: 'newly', type: 'newly'},
    { id: 'recentProducts', type: 'recent' },
    { id: 'orgs', type: 'orgs' },
  ]);

  const onDragUpdate = (result) => {
    // console.log(result);
    return;
  };

  const onDragEnd = (result) => {
    const newCards = Array.from(cards);

    if (!result.destination) {
      return setCards(newCards); // Drop outside the list
    }
  
    const { source, destination } = result;

    const [removed] = newCards.splice(source.index, 1);
    newCards.splice(destination.index, 0, removed);
  
    return setCards(newCards);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <div className={classes.header}>
            <p className={classes.title}>{getTitle()}</p>
          </div>
          <DragDropContext onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided) => (
                <Grid
                  container
                  className={classes.dashboard}
                  spacing={2}
                  justifyContent="flex-start"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {cards.map((card, index) => (
                    <Draggable key={card.id} draggableId={card.id} index={index}>
                      {(provided) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          {card.type === 'link' ? (
                            <DefaultLinkInfoCard
                              className={classes.link}
                              title={card.title}
                              href={card.href}
                              verbiage={card.verbiage}
                            />
                          ) : card.type === 'newly' ? (
                            <NewlyAvailableCard />
                          ) : card.type === 'quicklinks' ? (
                            <QuickLinksCard />
                          ) : card.type === 'recent' && me?.userId ? (
                            <MyRecentProductsCard userId={me.userId} />
                          ) : card.type === 'orgs' && me?.userId  && !userUtil.isEmployee(me) ? (
                            <MyOrgsCard />
                          ) : null }
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </Container>
      </div>
    </React.Fragment>
  );
}