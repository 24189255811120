// Packages
import React, { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

// MUI Components
import { Grid, Container, Hidden, Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import Dialog from "@material-ui/core/Dialog";
import Drawer from "@material-ui/core/Drawer";

// Components
import BrowsePicker from "components/BrowsePicker";
import BrowseResults from "components/BrowseResults";
import DownloadMaterialsDialogV2 from "components/DownloadMaterialsDialogV2";
import DownloadDocumentDialogV2 from "components/DownloadDocumentDialogV2";
import ProductRequestModal from "components/ProductRequestModal";
import CategoryTypesInfo from "components/CategoryTypesInfo";
import PackageTypesInfo from "components/PackageTypesInfo";


// Services
import * as AssetApi from "apis/AssetApi";
import { fixMaterialTypeForAPI } from "util/commonUtil";

// Data
import STATES from "enums/STATES";
import PLANS from "enums/PLANS";
import PROGRAMS from "enums/PROGRAMS";

import PACKAGE_TYPES from "enums/PACKAGE_TYPES";

// Styles
import { useStyles } from "./styles";
import DisclaimerLanguageDialog from "components/DisclaimerLanguageDialog";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function BrowseScreen(props) {
  const classes = useStyles();
  const initalQuery = useQuery().get("q") || "";

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [line, setLine] = useState([]);
  const [lineClass, setLineClass] = useState([]);
  const [loading, setLoading] = useState(false);
  const [packageType, setPackageType] = useState([]);
  const [packageTypeCategory, setPackageTypeCategory] = useState([]);
  const [apiPackageTypes, setAPIPackageTypes] = useState([]);
  const [term, setTerm] = useState(initalQuery);
  const [excludeFileContent, setExcludeFileContent] = useState("Y");
  const [states, setStates] = useState([]);
  const [supportingDocsType, setSupportingDocsType] = useState([]);
  const [searchTerm, setSearchTerm] = useState(initalQuery);
  const [scrollId, setScrollId] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [showSingleDialog, setShowSingleDialog] = useState(false);
  const [showDisclaimerLanguageDialog, setShowDisclaimerLanguageDialog] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState("");
  const [effectiveOldestDate, setEffectiveOldestDate] = useState("");
  const [searchSize, setSearchSize] = useState(30);
  const [uiChanger, setUiChanger] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectablePlans, setSelectablePlans] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [selectablePrograms, setSelectablePrograms] = useState([]);
  const [renderStates, setRenderStates] = useState([]);
  const [tempStates, setTempStates] = useState([]);
  const [allStatesSelected, setAllStatesSelected] = useState(false);
  const [resetMaterialTypesTop, setResetMaterialTypesTop] = useState(false);
  const [doSingleDownload, setDoSingleDownload] = useState(false);
  const [filesArr, setFilesArr] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSubMaterialTypes, setShowSubMaterialTypes] = useState(false);
  const [subMaterialTypes, setSubMaterialTypes ] = useState({});
  const [showDownloads, setShowDownloads] = useState(false);
  const [debounceSearchTerm, setDebounceSearchTerm] = useState("");
  const [aggs, setAggs] = useState();
  const [showCategories, setShowCategories] = useState(false);
  const [showPackagesInfo, setShowPackagesInfo] = useState(false);
  const [sort, setSort] = useState("newest");



  const removeQueryParam = () => {
    if (searchParams.has('q')) {
      searchParams.delete('q');
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Browse Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
      send_to: `${process.env.REACT_APP_GA}`
    });
  }, []);  

  const updateUI = () => {
    //use this to force the Effects to call the search API
    setUiChanger(!uiChanger);
  };
  
  const [snack, setSnack] = useState({
    open: false,
    note: null,
    severity: "info",
  });
  const [productLineHasClasses, setProductLineHasClasses] = useState(false);

  const handleRequestModal = () => {
    setShowRequestModal((prevState) => !prevState);
  };

  useEffect(() => {setTerm(searchTerm);}, [searchTerm]);

  useEffect(() => {
    if( window.innerHeight * window.innerWidth > 11839000 ){
      setSearchSize(100);
    }
    else if( window.innerHeight * window.innerWidth > 1450000 ){
      setSearchSize(60);
    }
    else if( window.innerHeight * window.innerWidth > 1000000 ){
      setSearchSize(40);
    }
    else{
      setSearchSize(30);
    }
    performSearch();
  }, [
    line,
    apiPackageTypes,
    term,
    excludeFileContent,
    states,
    supportingDocsType,
    lineClass,
    effectiveDate,
    effectiveOldestDate,
    uiChanger,
    plans,
    programs,
    packageTypeCategory,
    packageType,
    sort,
  ]);

  function openDownloadDrawer(){
    setShowDownloads(false);
    setShowDownloads(true);
    //if(!showDownloads){
    //  setShowDownloads(true);
    //}
  }

  async function performSearch() {
    const fixedPackageTypesTop = packageType.map((type) => type.title);
    const packageObjects = [];
    const fixedPackageTypesCategories = packageTypeCategory.map((key) => {
      for (const type in PACKAGE_TYPES) {
        if (PACKAGE_TYPES[type].publicationType === key) {
          packageObjects.push(PACKAGE_TYPES[type]);
          return PACKAGE_TYPES[type].title;
        }
      }
    });

    const fixedPackageTypes = fixMaterialTypeForAPI(fixedPackageTypesCategories, fixedPackageTypesTop, packageObjects);
  
    setLoading(true);
    setResults(null);
    const fixedPlans = plans?.map((plan) => PLANS[plan].key);
    const fixedPrograms = programs?.map((program) => PROGRAMS[program].keyAPI.toUpperCase());//key
    //if (!initalQuery && (line.length === 0 || states.length === 0)) {
    //  setLoading(false);
    //  return;
    //}
    const filters = {
      excludeFileContent: excludeFileContent,
      size: searchSize,
      productLine: line,
      publicationType: fixedPackageTypes,
      states: states?.map((name) => STATES[name].key),
      imgClass_s: lineClass,
      publicationTypeCategory_query: fixedPackageTypesCategories,
      plans: fixedPlans,
      programs: fixedPrograms,
      sort: sort,
      ...(!!effectiveDate && { effectiveDate }),
      ...(!!effectiveOldestDate && { effectiveOldestDate }),
    };

    trackSearch(term, filters);

    const tempResults = await AssetApi.search(term, filters);
    if (scrollId) await AssetApi.scrollDelete(scrollId);
    setScrollId(tempResults._scroll_id); //store the scollId. it can change
    setAggs(tempResults['aggregations']);
    setResults(tempResults);
    setLoading(false);
    if(term && term.length > 0){
      setShowCategories(true);
      setShowPackagesInfo(true);
    }
  }

  async function loadMore() {
    if (!scrollId) return;

    const tempResults = await AssetApi.scroll(scrollId);
    if (!tempResults?.hits?.hits?.length) {
      console.warn("tried to scroll, skipping", tempResults);
      return;
    }

    setScrollId(tempResults._scroll_id); //store the scollId. it can change
    results.hits.hits = results.hits.hits.concat(tempResults.hits.hits);
    setResults(Object.assign({}, results));
  }

  function trackSearch(searchTerm, filters) {
    if (searchTerm === null || searchTerm.trim() === "")
      return;

    window.gtag("event", "search", {
      search_term: searchTerm,
      event_category: "search",
      event_label: JSON.stringify(filters),
      value: 1,
    });
  }  

  function clearAll() {
    setLine([]);
    setPackageType([]);
    setPackageTypeCategory([]);
    setAPIPackageTypes([]);
    setTerm("");
    setExcludeFileContent("Y");
    setLineClass([]);
    setSupportingDocsType([]);
    setScrollId();
    setStates([]);
    setEffectiveDate(null);
    setEffectiveOldestDate(null);
    setSearchTerm("");
    setTempStates([]);
    setRenderStates([]);
    setAllStatesSelected(false);
    setResetMaterialTypesTop(false);
    setPlans([]);
    setSelectablePlans([]);
    removeQueryParam();
    setShowSubMaterialTypes(false);
    setSubMaterialTypes({});
    setDebounceSearchTerm("");
    setAggs();
    setSort();
  }

  function getBrowserPicker() {
    return (
      <BrowsePicker
        results={results}
        line={line}
        setLine={setLine}
        lineClass={lineClass}
        setLineClass={setLineClass}
        packageType={packageType}
        setPackageType={setPackageType}
        apiPackageTypes={apiPackageTypes}
        packageTypeCategory={packageTypeCategory}
        setPackageTypeCategory={setPackageTypeCategory}
        setAPIPackageTypes={setAPIPackageTypes}
        term={term}
        excludeFileContent={excludeFileContent}
        effectiveDate={effectiveDate}
        setEffectiveDate={setEffectiveDate}
        effectiveOldestDate={effectiveOldestDate}
        setEffectiveOldestDate={setEffectiveOldestDate}
        setTerm={setTerm}
        setExcludeFileContent={setExcludeFileContent}
        states={states}
        setStates={setStates}
        supportingDocsType={supportingDocsType}
        setSupportingDocsType={setSupportingDocsType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        clearAll={clearAll}
        productLineHasClasses={productLineHasClasses}
        setProductLineHasClasses={setProductLineHasClasses}
        updateUI={updateUI}
        plans={plans}
        setPlans={setPlans}
        selectablePlans={selectablePlans}
        setSelectablePlans={setSelectablePlans}
        programs={programs}
        setPrograms={setPrograms}
        selectablePrograms={selectablePrograms}
        setSelectablePrograms={setSelectablePrograms}
        renderStates={renderStates}
        setRenderStates={setRenderStates}
        tempStates={tempStates}
        setTempStates={setTempStates}
        allStatesSelected={allStatesSelected}
        setAllStatesSelected={setAllStatesSelected}
        resetMaterialTypesTop={resetMaterialTypesTop}
        setResetMaterialTypesTop={setResetMaterialTypesTop}
        showSubMaterialTypes={showSubMaterialTypes}
        setShowSubMaterialTypes={setShowSubMaterialTypes}
        subMaterialTypes={subMaterialTypes}
        setSubMaterialTypes={setSubMaterialTypes}
        showDownloads={showDownloads}
        setShowDownloads={setShowDownloads}
        debounceSearchTerm={debounceSearchTerm}
        setDebounceSearchTerm={setDebounceSearchTerm}
      ></BrowsePicker>
    );
  }

  const getPackageTypesInfos = () => {
    return (
      <PackageTypesInfo
        aggs={aggs}
        showPackagesInfo={showPackagesInfo}
        setShowPackagesInfo={setShowPackagesInfo}
        setPackageType={setPackageType}
        setPackageTypeCategory={setPackageTypeCategory}
      >
      </PackageTypesInfo>
    );
  };

  return (
    <React.Fragment>
      <Container maxWidth={true} className={classes.container}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snack.open}
          onClose={() => setSnack({ open: false, severity: snack.severity })}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          <Alert severity={snack.severity} sx={{ width: "100%" }}>
            {<span>{snack.note}</span>}
          </Alert>
        </Snackbar>
        <Grid container spacing={1} alignItems="stretch" style={{ flexGrow: 1 }}>
          <Hidden smDown>
            <Grid item md={3} lg={2}>
              {getBrowserPicker()}
              <CategoryTypesInfo
                aggs={aggs}
                showCategories={showCategories}
                setShowCategories={setShowCategories}
                setPackageTypeCategory={setPackageTypeCategory}
                setPackageType={setPackageType}
              >
              </CategoryTypesInfo>
              {getPackageTypesInfos()}
            </Grid>
          </Hidden>
          <Grid item xs={5} md={5} lg={5} style={{ flexGrow: 1, maxWidth: "100%", flexBasis: "80%" }}>
            <BrowseResults
              setDrawerOpen={setDrawerOpen}
              handleRequestModal={handleRequestModal}
              results={results}
              line={line}
              sort={sort}
              setSort={setSort}
              loading={loading}
              loadMore={loadMore}
              download={() => setShowDisclaimerLanguageDialog(true)}
              isProductSelected={line.length > 0}
              setFilesArr={setFilesArr}
              setDoSingleDownload={setDoSingleDownload}
              setShowDisclaimerLanguageDialog={setShowDisclaimerLanguageDialog}
              setShowSingleDialog={setShowSingleDialog}
              
            />
          </Grid>
        </Grid>
      </Container>
      {drawerOpen && (
        <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <div className={classes.drawer}>{getBrowserPicker()}</div>
        </Drawer>
      )}
      {showDisclaimerLanguageDialog && (
        <DisclaimerLanguageDialog
          message="IF YOU ARE USING AAIS ADVISORY PRODUCTS OR SERVICES
          AAIS IS REQUIRED TO REPORT TO STATE REGULATORS ANY USE OF ADVISORY CONTENT AND USE OF THESE MATERIALS MAY REQUIRE A GRANT OF FILING AUTHORIZATION.
          PRIOR TO USING THIS CONTENT, PLEASE CONTACT AN AAIS ENGAGEMENT MANAGER AT MEMBERSHIP@AAISONLINE.COM TO DISCUSS YOUR USE OF ANY AAIS PRODUCTS AND SERVICES."
          handleCancel={() => {
            setShowDisclaimerLanguageDialog(false);
          }}
          handleAgree={() => {
            setShowDisclaimerLanguageDialog(false);
            if(doSingleDownload === true){
              setShowSingleDialog(true);
              setDoSingleDownload(false);
            }
            else{
              setShowDialog(true);
            }
            
          }}
        />
      )}
      {showDialog && (
        <DownloadMaterialsDialogV2
          lineKey={line}
          states={states?.map((name) => STATES[name].key)}
          packageTypes={packageType}
          packageTypeCategory={packageTypeCategory}
          imgClass_s={lineClass}
          publicationTypeCategory_query={supportingDocsType}
          searchTerm={term}
          excludeFileContent={excludeFileContent}
          handleClose={() => setShowDialog(false)}
          setSnack={setSnack}
          effectiveDate={effectiveDate}
          effectiveOldestDate={effectiveOldestDate}
          plans={plans}
          programs={programs}
          openDownloadDrawer={() => openDownloadDrawer()}
        ></DownloadMaterialsDialogV2>
      )}
      {showSingleDialog && (
        <DownloadDocumentDialogV2
          filesArr={filesArr}
          handleClose={() => setShowSingleDialog(false)}
          setSnack={setSnack}
        ></DownloadDocumentDialogV2>
      )}
      <Dialog
        open={showRequestModal}
        scroll="paper"
        onClose={handleRequestModal}
        className={classes.productModalContainer}
      >
        <ProductRequestModal
          handleClose={handleRequestModal}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          excludeFileContent={excludeFileContent}
          setExcludeFileContent={setExcludeFileContent}
          line={line}
          setLine={setLine}
          lineClass={lineClass}
          setLineClass={setLineClass}
          packageType={packageType}
          setPackageType={setPackageType}
          packageTypeCategory={packageTypeCategory}
          setPackageTypeCategory={setPackageTypeCategory}
          apiPackageTypes={apiPackageTypes}
          setAPIPackageTypes={setAPIPackageTypes}
          supportingDocsType={supportingDocsType}
          setSupportingDocsType={setSupportingDocsType}
          states={states}
          setStates={setStates}
          setTerm={setTerm}
          effectiveDate={effectiveDate}
          setEffectiveDate={setEffectiveDate}
          effectiveOldestDate={effectiveOldestDate}
          setEffectiveOldestDate={setEffectiveOldestDate}
          handleRequestModal={handleRequestModal}
          setSnack={setSnack}
          imgClass_s={lineClass}
          plans={plans}
          setPlans={setPlans}
          selectablePlans={selectablePlans}
          setSelectablePlans={setSelectablePlans}
          programs={programs}
          setPrograms={setPrograms}
          selectablePrograms={selectablePrograms}
          setSelectablePrograms={setSelectablePrograms}
          renderStates={renderStates}
          setRenderStates={setRenderStates}
          tempStates={tempStates}
          setTempStates={setTempStates}
          allStatesSelected={allStatesSelected}
          setAllStatesSelected={setAllStatesSelected}
          initalQuery={initalQuery}
        />
      </Dialog>
    </React.Fragment>
  );
}
