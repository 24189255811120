// Packages
import React, { useState, useEffect } from "react";

// MUI Components
import { Grid, CardContent, Button } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Stack } from "@mui/material";
import Popper from "@mui/material/Popper";




// MUI Icons
import ClearIcon from "@material-ui/icons/Clear";
import EventIcon from "@material-ui/icons/Event";
import StatesIcon from "@material-ui/icons/Public";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

// Styles
import { useStyles, popperStyles } from "./styles";

// Data
import STATES from "enums/STATES";
import LINES from "enums/LINES";
import PACKAGE_TYPES from "enums/PACKAGE_TYPES";
import PLANS from "enums/PLANS";
import PROGRAMS from "enums/PROGRAMS";

export default function Filters(props) {
  const classes = useStyles();
  const {
    searchTerm,
    setSearchTerm,
    setProductLineHasClasses,
    productLineHasClasses,
    modal,
    imgClass_s,
    setImgClass_s,
    updateUI,
    plans,
    setPlans,
    selectablePlans,
    setSelectablePlans,
    excludeFileContent,
    setExcludeFileContent,
    showSubMaterialTypes,
    setShowSubMaterialTypes,
    subMaterialTypes,
    setSubMaterialTypes
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedCurrentDate, setCheckedCurrentDate] = useState("N");
  const [checkedCurrentOldestDate, setCheckedCurrentOldestDate] = useState("N");
  const [checkedMaterialTypes, setCheckedMaterialTypes] = useState({});
  const [innerProductLineHasClasses, setInnerProductLineHasClasses] = useState(props.productLineHasClasses);

  const [showPlan, setShowPlan] = useState(false);
  const [showProgram, setShowProgram] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchTerm(props.debounceSearchTerm);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [props.debounceSearchTerm, 500]);

  //plans effect
  useEffect(() => {
    let tempShowPlan = false;
    const tempPlans = [];

    if(props.line ){
      if(props.line.includes('HO')){
        if(props.states && props.states.includes('NORTH_CAROLINA')){
          tempPlans.push('BY_PERIL_EXEMPT');
          tempPlans.push('COMPOSITE');
        }
        if(props.states && props.states.includes('VIRGINIA')){
          tempPlans.push('BY_PERIL');
          tempPlans.push('COMPOSITE');
          tempPlans.push('COMPOSITE_EXEMPT');
        }
        if(!props.states || ( !props.states.includes('NORTH_CAROLINA') && !props.states.includes('VIRGINIA') ) ){
          tempPlans.push('BY_PERIL');
          tempPlans.push('COMPOSITE');
        }
        tempShowPlan = true;

      }
      if(props.line.includes('DP')){
        if(props.states && props.states.includes('NORTH_CAROLINA')){
          tempPlans.push('EXEMPT');
          tempShowPlan = true;
        }
      }
      if(props.line.includes('MHO')){
        if(props.states && props.states.includes('NORTH_CAROLINA')){
          tempPlans.push('ALTERNATE');
          tempShowPlan = true;
        }
      }
      const tPlans = tempPlans.filter((x, i) => i === tempPlans.indexOf(x));
      const tSelectable = props.selectablePlans ? props.selectablePlans : [];
      if( ( tPlans.length !== tSelectable.length || JSON.stringify(tPlans) !== JSON.stringify(tSelectable) ) ){
        props.setSelectablePlans(tPlans);
      }
      if(tempShowPlan !== showPlan){
        setShowPlan(tempShowPlan);
      }
    }
  }, [props.states, props.line, props.plans, props.renderStates, props.tempStates, anchorEl]);

  //programs effect
  useEffect(() => {
    let tempShowProgram = false;
    const tempPrograms = [];

    if(props.line){
      props.line.map((lineItem) => {
        Object.keys(PROGRAMS).map((programKey) => {
          if(PROGRAMS[programKey]['line'] === lineItem){
            tempPrograms.push(programKey);
            tempShowProgram = true;
          }
        });
      });
      const tPrograms = tempPrograms.filter((x, i) => i === tempPrograms.indexOf(x));
      const tSelectable = props.selectablePrograms ? props.selectablePrograms : [];
      if( ( tPrograms.length !== tSelectable.length || JSON.stringify(tPrograms) !== JSON.stringify(tSelectable) ) ){
        props.setSelectablePrograms(tPrograms);
      }
      if(tempShowProgram !== showPlan){
        setShowProgram(tempShowProgram);
      }
    }
  }, [props.states, props.line, props.programs, props.renderStates, props.tempStates, anchorEl]);


  useEffect(() => {
    const productLine = props.line;
    if (productLine.filter((line) => LINES[line].classes).length > 0) {
      setInnerProductLineHasClasses(true);
    } else {
      props.setLineClass([]);
      setInnerProductLineHasClasses(false);
    }
  }, [props.line]);

  useEffect(() => {
    const stillValidPlans = [];
      for(const x in props.plans){
        if( props.selectablePlans.includes(props.plans[x]) ){
          stillValidPlans.push(props.plans[x]);
        }
      }
      props.setPlans(stillValidPlans);
  }, [props.selectablePlans]);

  useEffect(() => {
    const subTypesArr = {};
    const currentPackageTypes = props.packageType;
    const updatePackageTypes = [];

    props.packageTypeCategory.map((value) => {
      for (const type in PACKAGE_TYPES) {
        if (PACKAGE_TYPES[type].publicationType === value && PACKAGE_TYPES[type].types) {
          const thisPackage = PACKAGE_TYPES[type];
          const thisTypes = thisPackage.types;
          const subTypes = Object.values(thisTypes);
          subTypes.map((subType) => {
            subType['top'] = PACKAGE_TYPES[type].title;
            subTypesArr[subType.publicationTypeCategory_query] = subType;
            currentPackageTypes.map((aCurrentlySelectedPackage) => {
              if(subType.key === aCurrentlySelectedPackage.key){
                updatePackageTypes.push(subType);
              }
            });
          });
        }
      }
    });
    setSubMaterialTypes(subTypesArr);
    if(Object.values(subTypesArr).length === 0){
      setShowSubMaterialTypes(false);
      props.setPackageType([]);
    }
    else{
      setShowSubMaterialTypes(true);
    }
    if(props.packageTypeCategory.length === 0){
      props.setPackageType([]);
      setShowSubMaterialTypes(false);
      setSubMaterialTypes({});
    }
  }, [props.packageTypeCategory]);

  useEffect(() => {
    const subTypesArr = {};
    const currentPackageTypes = props.packageType;
    const updatePackageTypes = [];
    props.packageTypeCategory.map((value) => {
      for (const type in PACKAGE_TYPES) {
        if (PACKAGE_TYPES[type].publicationType === value && PACKAGE_TYPES[type].types) {
          const thisPackage = PACKAGE_TYPES[type];
          const thisTypes = thisPackage.types;
          const subTypes = Object.values(thisTypes);
          subTypes.map((subType) => {
            subType['top'] = PACKAGE_TYPES[type].title;
            subTypesArr[subType.publicationTypeCategory_query] = subType;
            currentPackageTypes.map((aCurrentlySelectedPackage) => {
              if(subType.key === aCurrentlySelectedPackage.key){
                updatePackageTypes.push(subType);
              }
            });
          });
        }
      }
    });
    setSubMaterialTypes(subTypesArr);
    //props.setPackageType(updatePackageTypes);
  }, [props.packageType]);

  function doTopReset(){
    props.setPlans([]);
    setSubMaterialTypes({});
    setShowSubMaterialTypes(false);
  }
  
  const handleLineChange = (event, value) => {
    const productLine = value.map((value) => value.key);
    if (productLine.filter((line) => LINES[line].classes).length > 0) {
      setProductLineHasClasses(true);
    } else {
      props.setLineClass([]);
      setProductLineHasClasses(false);
    }
    props.setLine(productLine);
  };

  const handlePackageTypeChange = (event, value) => {
    const packageType = value;//value.map((value) => value.title);//publicationTypeCategory_query
    props.setPackageType(packageType);
  };
  const handlePackageTypeCategoryChange = (event, value) => {
    const packageType = value.map((value) => value.publicationType);
    props.setPackageTypeCategory(packageType);
  };


  function checkSelected(option){
    if(props.tempStates.includes(option)){
      return true;
    }
    return false;
  }


  function clearEffectiveDate() {
    setCheckedCurrentDate("N");
    props.setEffectiveDate(null);
  }
  function clearEffectiveOldestDate() {
    setCheckedCurrentOldestDate("N");
    props.setEffectiveOldestDate(null);
  }
  function handleClr(e) {
    e.stopPropagation();
    clearEffectiveDate();
  }
  function handleOldestClr(e) {
    e.stopPropagation();
    clearEffectiveOldestDate();
  }

  function updateSupportingDocs(data=null){
    const supportData = data ? data : checkedMaterialTypes;
    const supportingDocsType = [];
    let aCount = 0;
    let bCount = 0;

    for(const topPKG in PACKAGE_TYPES){
      aCount++;
      const temp = {};
      const thisKey = PACKAGE_TYPES[topPKG]['key'];
      if(supportData[thisKey] === true){
        bCount++;
        temp['top'] = thisKey;
        if(PACKAGE_TYPES[topPKG]['types']){
          temp['bot'] = [];
          
          for(const y in PACKAGE_TYPES[topPKG]['types']){
            aCount++;
            if(supportData[PACKAGE_TYPES[topPKG]['types'][y]['key']]){
              bCount++;
              temp['bot'].push(PACKAGE_TYPES[topPKG]['types'][y]['title']);
            } 
          }
        }
        supportingDocsType.push(temp);
      }
    }
    if(aCount === bCount){
      supportingDocsType.length = 0;
      //if all boxes are checked, do not provide search critera
    }
    

    props.setSupportingDocsType(supportingDocsType);
  }
  const handleLinePlanChange = (event, value) => {
    const productLinePlans = value.map((value) => value.key);
    props.setPlans(productLinePlans);
  };
  const handleLineProgramChange = (event, value) => {
    const productLinePrograms = value.map((value) => value.key);
    props.setPrograms(productLinePrograms);
  };
  const handleLineClassChange = (event, value) => {
    const productLineClass = value.map((value) => value.key);
    props.setLineClass(productLineClass);
  };


  const handleSupportingDocsTypeChange = (event, value) => {
    const supportingDocsType = value.map((value) => value.publicationTypeCategory_query);
    //props.setSupportingDocsType(supportingDocsType);
  };

  function handleClickForMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  async function handleChangeDate(event) {
    const yearCheck = new Date(event).getFullYear();
    if(yearCheck < 1000){
      return null;
    }
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const selectedDate = new Date(event).toLocaleDateString("en-US", options);
    await props.setEffectiveDate(selectedDate);
    setCheckedCurrentDate("N");
  }

  async function handleChangeOldestDate(event) {
    const yearCheck = new Date(event).getFullYear();
    if(yearCheck < 1000){
      return null;
    }
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const selectedDate = new Date(event).toLocaleDateString("en-US", options);
    await props.setEffectiveOldestDate(selectedDate);
    setCheckedCurrentOldestDate("N");
  }

  async function handleCheckExcludeFileContent(event) {
    await props.setExcludeFileContent(event.target.checked ? "N" : "Y");
  }

  async function handleCheckCurrentDate(event) {
    if (!event.target.checked) {
      clearEffectiveDate();
      clearEffectiveOldestDate();
      return;
    }
    setCheckedCurrentDate("Y");

    const currentDate = new Date();
    const formatDate = currentDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    await props.setEffectiveDate(formatDate);

    const oldestDate = new Date("1900-01-01");
    const formatOldestDate = oldestDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    await props.setEffectiveOldestDate(formatOldestDate);
  }

  function titleToKey(bestTarget){
    const bestKey = Object.keys(STATES).find(stateKey => STATES[stateKey].title === bestTarget);
    return STATES[bestKey].key;
  }

  const handleStateArray = (event, value) => {
    //TODO refactor logic
    const currentTargetString = event.currentTarget.outerHTML;
    const bestTargetA =  currentTargetString.substring(-2, currentTargetString.indexOf('</li>'));
    const bestTargetTitle = bestTargetA.substring(bestTargetA.lastIndexOf('</span>') + 7);
    const bestTarget = bestTargetTitle ? titleToKey(bestTargetTitle) : bestTargetTitle;
    const tempStates = props.tempStates;
    if(bestTarget.length === 0){
      props.setStates(value);
      props.setTempStates(value);
      props.setRenderStates(value);
      setAnchorEl(!anchorEl);
      updateUI();
    }//if(bestTarget.length === 0){
    else{//if bestTarget is valid
      const bestKey = Object.keys(STATES).find(stateKey => STATES[stateKey].key === bestTarget);
    
      if(tempStates.includes(bestKey)){

        const index = tempStates.indexOf(bestKey);
        if (index > -1) {
          tempStates.splice(index, 1);
        }
        props.setTempStates(tempStates);
        props.setStates(tempStates);
        props.setRenderStates(tempStates);
        setAnchorEl(!anchorEl);
        updateUI();
      }
      else{
        tempStates.push(bestKey);
        props.setTempStates(tempStates);
        props.setStates(tempStates);
        setAnchorEl(!anchorEl);
        updateUI();
      }


      props.setTempStates(tempStates);
      if(tempStates.length === Object.keys(STATES).length - 1){
        props.setTempStates(Object.keys(STATES));
      }
      
      props.setStates(tempStates);
      if(!props.allStatesSelected){
        props.setRenderStates(tempStates);
      }
      updateUI();
      
    }
    
    setAnchorEl(!anchorEl);
    updateUI();
    return tempStates;
    
  };

  const handleMaterialType = (packageTypes) => {
    const options = packageTypes
      .map((packageTypeKey) => {
        return Object.values(PACKAGE_TYPES[packageTypeKey].types);
      })
      .flat();
    return options;
  };
  

  const supportingDocsOptions = (packageTypes) => {
    const options = packageTypes
      .map((packageTypeKey) => {
        return Object.values(PACKAGE_TYPES[packageTypeKey].types);
      })
      .flat();
    return options;
  };

  const styledPopper = (props) => <Popper {...props} style={popperStyles} />;
  const styledPopperState = (props) => <Popper {...props} style={{width: "501px",  placement: 'bottom-start'}} />;


  function handlekeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      props.setTerm(searchTerm);
    }
  }
  const [dateOpen, setDateOpen] = useState(false);
  const [dateOldestOpen, setDateOldestOpen] = useState(false);


  return (
    <CardContent>
      {(
          <>
            {/* // Search Section */}
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                disabled={modal}
                autoCorrect="off"
                spellCheck="false"
                label="Search"
                placeholder="Search"
                data-test="browseScreenSearch"
                value={props.debounceSearchTerm}
                onChange={(event) => props.setDebounceSearchTerm(event.target.value)}
                inputProps={{
                  onKeyDown: handlekeyDown,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {props.debounceSearchTerm && (
                        <IconButton
                          disabled={modal}
                          type="submit"
                          color="primary"
                          onClick={() => {
                            props.setTerm("");
                            setSearchTerm("");
                            props.setDebounceSearchTerm("");
                          }}
                          component="span"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={excludeFileContent === "N"}
                    onChange={handleCheckExcludeFileContent}
                    color="primary"
                    disabled={modal}
                  />
                }
                label="Include File Text"
              />              
            </Grid>
          </>
        )}
      <Grid container spacing={3}>
        {/* Product Line Selection */}
        <Grid item xs={12} className={classes.section}>
          <Autocomplete
            multiple
            options={Object.values(LINES)}
            value={
              props?.line?.map((line) => {
                return LINES[line];
              }) || null
            }
            disableCloseOnSelect
            disabled={modal}
            disablePortal
            PopperComponent={styledPopper}
            getOptionLabel={(option) => {
              return `${option.key} - ${option.title}`;
            }}
            labelid="product-select-label"
            id="product-select"
            data-test="browseProduct"
            label="Product"
            onChange={handleLineChange}
            renderOption={(props, option, { selected }) => (
              !modal &&
              <li {...props}>
                <Checkbox
                  id={`browseProduct-productSelect-${option.key}`}
                  icon={
                    <CheckBoxOutlineBlankIcon
                      color="disabled"
                      fontSize="small"
                    />
                  }
                  checkedIcon={
                    <CheckBoxIcon color="disabled" fontSize="small" />
                  }
                  style={{ marginRight: 8 }}
                  checked={selected}
                  disabled={modal}
                  value={option.key}
                />
                {`${option.key} - ${option.title}`}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                id="product-select-label"
                {...params}
                label="Product"
                placeholder="Product"
                disabled={modal}
              />
            )}
          />
        </Grid>
        {/* Classes Section */}
        {(productLineHasClasses || innerProductLineHasClasses) && (
          <Grid item xs={12} className={classes.section}>
            <Autocomplete
              multiple
              options={Object.values(LINES)
                .map((line) => {
                  if (line.classes) {
                    return Object.values(line.classes);
                  }
                })
                .flat()
                .filter((line) => line)}
              value={props?.lineClass
                ?.map((line) => {
                  return Object.values(LINES)
                    .map((lineValue) => {
                      if (lineValue.classes) {
                        return Object.values(lineValue.classes).filter(
                          (lineClass) => lineClass.key === line
                        );
                      }
                    })
                    .flat();
                })
                .flat()
                .filter((line) => line)}
              disableCloseOnSelect
              disabled={modal}
              disablePortal
              PopperComponent={styledPopperState}
              getOptionLabel={(option) => option.title}
              labelid="classes-select-label"
              id="classes-select"
              data-test="classes"
              label="classes"
              onChange={handleLineClassChange}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={
                      <CheckBoxOutlineBlankIcon
                        color="disabled"
                        fontSize="small"
                      />
                    }
                    checkedIcon={
                      <CheckBoxIcon color="disabled" fontSize="small" />
                    }
                    style={{ marginRight: 8 }}
                    checked={selected}
                    disabled={modal}
                    data-test={`select-classes-${option.key}`}
                    value={option.key}
                  />
                  {`${option.key} - ${option.title}`}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  id="classes-select-label"
                  disabled={modal}
                  {...params}
                  label="Classes"
                  placeholder="Classes"
                />
              )}
            />
          </Grid>
        )}
        {/* State Section */}
        <Grid item xs={12} className={classes.section}>
          <Autocomplete
            multiple
            options={Object.keys(STATES)}
            value={
              props.renderStates?.map((state) => {//props?.states
                return state;
              }) || null
            }
            disableCloseOnSelect
            disabled={modal}
            disablePortal
            PopperComponent={styledPopperState}
            getOptionLabel={(option) => {
              return `${STATES[option].title}`;//option is STATES Object 
            }}
            labelid="state-select-label"
            id="state-select"
            data-test="browseState"
            label="State"
            onChange={handleStateArray}
            renderOption={(props, option, { selected }) => (
              !modal &&
              <li {...props}>
                <Checkbox
                  icon={
                    <CheckBoxOutlineBlankIcon
                      color="disabled"
                      fontSize="small"
                    />
                  }
                  checkedIcon={
                    <CheckBoxIcon color="disabled" fontSize="small" />
                  }
                  style={{ marginRight: 8 }}
                  checked={checkSelected(option) || props.allStatesSelected}
                  disabled={modal}
                  key={option}
                  data-test={`select${STATES[option].key}`}
                  value={STATES[option].key}
                />
                {`${STATES[option].title}`}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                id="state-select-label"
                {...params}
                label="Jurisdiction"
                placeholder="Jurisdiction"
                disabled={modal}
              />
            )}
          />
        </Grid>
        {/* Plan Section */}
        {showPlan && (
          <Grid item xs={12} className={classes.section}>
            <Autocomplete
              multiple
              options={props.selectablePlans?.map((plan) => {
                return PLANS[plan];
              }) || null}
              value={
                props.plans?.map((plan) => {
                  return PLANS[plan];
                }) || null
              }
              disableCloseOnSelect
              disabled={modal}
              disablePortal
              PopperComponent={styledPopperState}
              getOptionLabel={(option) => option.title}
              labelid="plans-select-label"
              id="plans-select"
              data-test="plans"
              label="plans"
              onChange={handleLinePlanChange}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={
                      <CheckBoxOutlineBlankIcon
                        color="disabled"
                        fontSize="small"
                      />
                    }
                    checkedIcon={
                      <CheckBoxIcon color="disabled" fontSize="small" />
                    }
                    style={{ marginRight: 8 }}
                    checked={selected}
                    disabled={modal}
                    data-test={`select-plan-${option.title}`}
                    value={option.key}
                  />
                  {`${option.title}`}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  id="plans-select-label"
                  disabled={modal}
                  {...params}
                  label="Plans"
                  placeholder="Plans"
                />
              )}
            />
          </Grid>
        )}
        {/* Program Section */}
        {showProgram && (
          <Grid item xs={12} className={classes.section}>
            <Autocomplete
              multiple
              options={props.selectablePrograms?.map((program) => {
                return PROGRAMS[program];
              }) || null}
              value={
                props.programs?.map((program) => {
                  return PROGRAMS[program];
                }) || null
              }
              disableCloseOnSelect
              disabled={modal}
              disablePortal
              PopperComponent={styledPopperState}
              getOptionLabel={(option) => { 
                return option.title;
              }}
              labelid="programs-select-label"
              id="programs-select"
              data-test="programs"
              label="programs"
              onChange={handleLineProgramChange}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={
                      <CheckBoxOutlineBlankIcon
                        color="disabled"
                        fontSize="small"
                      />
                    }
                    checkedIcon={
                      <CheckBoxIcon color="disabled" fontSize="small" />
                    }
                    style={{ marginRight: 8 }}
                    checked={selected}
                    disabled={modal}
                    data-test={`select-program-${option.title}`}
                    value={option.key}
                  />
                  {`${option.title}`}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  id="programs-select-label"
                  disabled={modal}
                  {...params}
                  label="Programs"
                  placeholder="Programs"
                />
              )}
            />
          </Grid>
        )}
        {/* Package Type Section */}
        <Grid item xs={12} className={classes.section}>
          <Autocomplete
            multiple
            disabled={!props.line || props.modal}
            options={Object.values(PACKAGE_TYPES)}
            disableCloseOnSelect
            disablePortal
            PopperComponent={styledPopper}
            getOptionLabel={(option) => option.title}
            labelid="packageTypeCategory-select-label"
            id="packageTypeCategory-select"
            data-test="packageTypeCategory"
            label="Material Type"
            onChange={handlePackageTypeCategoryChange}
            value={
              props?.packageTypeCategory?.map((packageType) => {
                for (const type in PACKAGE_TYPES) {
                  if (PACKAGE_TYPES[type].publicationType === packageType) {
                    return PACKAGE_TYPES[type];
                  }
                }
              }) || null
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={
                    <CheckBoxOutlineBlankIcon
                      color="disabled"
                      fontSize="small"
                    />
                  }
                  checkedIcon={
                    <CheckBoxIcon color="disabled" fontSize="small" />
                  }
                  style={{ marginRight: 8 }}
                  checked={selected}
                  data-test={`select-packageTypeCategory-${option.title}`}
                  value={option.title}
                />
                {option.title}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                id="packageType-select-label"
                {...params}
                label="Material Type"
                placeholder="Material Type"
              />
            )}
          />
        </Grid>

        {/* Sub Material Type Section */}
        {showSubMaterialTypes && (
          <Grid item xs={12} className={classes.section}>
            <Autocomplete
              multiple
              options={Object.values(subMaterialTypes)}
              value={
                props.packageType?.map((type) => {
                  return type;
                }) || null
              }
              disableCloseOnSelect
              disabled={modal}
              disablePortal
              PopperComponent={styledPopperState}
              getOptionLabel={(option) => option.title}
              InputLabelProps={{ shrink: true }} 
              labelid="packageType-select-label"
              id="packageType-select"
              data-test="packageType"
              label="Material Type"
              onChange={handlePackageTypeChange}
              navItem={true}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={
                      <CheckBoxOutlineBlankIcon
                        color="disabled"
                        fontSize="small"
                      />
                    }
                    checkedIcon={
                      <CheckBoxIcon color="disabled" fontSize="small" />
                    }
                    style={{ marginRight: 8 }}
                    checked={selected}
                    disabled={modal}
                    data-test={`select-packageType-${option.title}`}
                    value={option.title}
                  />
                  {`${option.title} (${option.top})`}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  id="packageType-select-label"
                  disabled={modal}
                  {...params}
                  label="Material Type Subcategory"
                  placeholder="Material Type Subcategory"
                  InputLabelProps={{ shrink: true }} 
                  style={{ shrink: true }}
                />
              )}
            />
          </Grid>
        )}

        {/* Status Section */}
        <Grid item xs={12} className={classes.section}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth={true}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack>
              <DatePicker
                  autoOk
                  variant="inline"
                  dateFormat="dd/MM/yyyy"
                  labelId="date-approval-picker-label-oldest"
                  id="date-approval-picker-oldest"
                  value={props.effectiveOldestDate}
                  className={classes.effective}
                  label="From"
                  onChange={handleChangeOldestDate}
                  onClose={() => setDateOldestOpen(false)}
                  open={dateOldestOpen && !modal}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={modal}
                      error={false}
                      onClick={() => setDateOldestOpen(true)}
                      InputProps={
                        {
                        endAdornment: props.effectiveOldestDate ? (
                          <IconButton
                            disabled={modal}
                            className={classes.effectiveIcon}
                            onClick={(e) => handleOldestClr(e)}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            disabled={modal}
                            className={classes.effectiveIcon}
                            onClick={() => setDateOldestOpen(true)}
                          >
                            <EventIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                  disabled={modal}
                ></DatePicker>
                <DatePicker
                  autoOk
                  variant="inline"
                  dateFormat="dd/MM/yyyy"
                  labelId="date-approval-picker-label"
                  id="date-approval-picker"
                  value={props.effectiveDate}
                  className={classes.effective}
                  label="To"
                  onChange={handleChangeDate}
                  onClose={() => setDateOpen(false)}
                  open={dateOpen && !modal}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={modal}
                      error={false}
                      onClick={() => setDateOpen(true)}
                      InputProps={{
                        endAdornment: props.effectiveDate ? (
                          <IconButton
                            disabled={modal}
                            className={classes.effectiveIcon}
                            onClick={(e) => handleClr(e)}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            disabled={modal}
                            className={classes.effectiveIcon}
                            onClick={() => setDateOpen(true)}
                          >
                            <EventIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                  disabled={modal}
                ></DatePicker>
              </Stack>
            </LocalizationProvider>
          </FormControl>
        </Grid>
        {props.children}
      </Grid>
    </CardContent>
  );
}
