import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI Components
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Container } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";

// Components
import SampleCard from 'components/SampleCard';
import ProductDialog from "components/ProductDialog";
import LandingPageDialog from "components/LandingPageDialog";


// Context
import MeContext from "contexts/MeContext";

// Services
import * as ProductApi from "apis/ProductApi";
import * as LandingPageApi from "apis/LandingPageApi";

// Styles
import { useStyles } from "./styles";

export default function ProductScreen(props) {
  
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const [states, setStates] = useState([]);
  const [allProductIds, setAllProductIds] = useState([]);
  const [allProductNames, setAllProductNames] = useState([]);
  const [currentLandingPage, setCurrentLandingPage] = useState();
  const [allProducts, setAllProducts] = useState([]);
  const [doReset, setDoReset] = useState();
  const [currentProductName, setCurrentProductName] = useState('');
  const [currentProduct, setCurrentProduct] = useState();
  const [currentLandingPageName, setCurrentLandingPageName] = useState("");

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [createLandingPageDialogOpen, setCreateLandingPageDialogOpen] = useState(false);
  const [landingPageId, setLandingPageId] = useState();
  const [productId, setProductId] = useState();
  const [productKeyName, setProductKeyName] = useState();
  const [currentLandingPageWidgets, setCurrentLandingPageWidgets] = useState();
  const [updateSearches, setUpdateSearches] = useState(false);
  const [singleResult, setSingleResult] = useState(false);
  const [allLandingPages, setAllLandingPages] = useState();
  const [productsReady, setProductsReady] = useState(false);
  const [landingPagesReady, setLandingPagesReady] = useState(false);
  const [landingPageProducts, setLandingPageProducts] = useState();
  const [displayLandingPageProducts, setDisplayLandingPageProducts] = useState();
  const [doInternalReset, setDoInternalReset] = useState(false);

  const landingPageNames = ['auto', 'commercial', 'farmag', 'inlandmarine', 'personal'];

  const navigate = useNavigate();
  const [snack, setSnack] = useState({
    open: false,
    note: null,
    severity: "info",
  });


  
  useEffect(() => {
    const thisProductId = props.productData ? props.productData : 'auto';

    if(productsReady === true && landingPagesReady === true && allLandingPages){
      let thisLandingPage = null;

      for(const i in allLandingPages){
        if(allLandingPages && allLandingPages[i] && allLandingPages[i].keyName === thisProductId){
          thisLandingPage = allLandingPages[i];
          //window.location.href = window.location.origin + `/#/product?q=${props.productData}`;
        }
      }
      if(thisLandingPage === null){
        navigate("/");
        return null;
      }
      
      setCurrentLandingPageName(thisLandingPage.name);
      const theseLandingPageProducts = thisLandingPage.products;
      setAllProductIds(theseLandingPageProducts);
      const landingPageWidgets = thisLandingPage.widgets;
      setCurrentLandingPageWidgets(landingPageWidgets);
      setLandingPageId(thisLandingPage.landingPageId);
      setCurrentLandingPage(thisLandingPage);
      setUpWidgets();

      const newProducts = [];

      allProducts.filter((product) => {
        if(thisLandingPage.products.includes(product.productId)){
          newProducts.push(product);
        }
      });
      if(!productKeyName){
        setProductKeyName(newProducts[0].keyName);
      }
      setLandingPageProducts(newProducts);
    }

  }, [allLandingPages, props.productData, productsReady, landingPagesReady]);

  useEffect(() => {
    if(landingPageProducts && landingPageProducts.length > 0){
      setCurrentProduct(landingPageProducts[0]);
      setProductKeyName(landingPageProducts[0].keyName);
      setProductId(landingPageProducts[0].productId);
      setUpWidgets();
      setDisplayLandingPageProducts(landingPageProducts);
    }
  }, [landingPageProducts]);
  

  useEffect(() => {
    /*window.gtag("event", "page_view", {
      page_title: "Product Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
      send_to: `${process.env.REACT_APP_GA}`
    });*/

    
    async function fetchLandingPages() {
      //initial load
      const tempLandingPages = await LandingPageApi.getAll();
      setAllLandingPages(tempLandingPages);
      const firstLandingPage = tempLandingPages[0];
      let thisLandingPage = null;//firstLandingPage;
      for(const i in tempLandingPages){
        if(tempLandingPages[i].keyName === props.productData){
          thisLandingPage = tempLandingPages[i];
          //window.location.href = window.location.origin + `/#/product?q=${props.productData}`;
        }
      }
      setLandingPagesReady(true);      
    }
    async function fetchProducts() {

      const apiAllProducts = await ProductApi.getAll();//getProducts(theseLandingPageProducts);
      setAllProducts(apiAllProducts);
      
    }
    fetchLandingPages();
    fetchProducts();
    setLoading(false);
  }, []);// searchParams.get('q')

  useEffect(() => {
    if(allProducts && allProducts.length > 0){
      let thisProduct = allProducts[0];
      const theseProductNames = [];
      let foundProduct = false;
      //words.filter((word) => word.length > 6);
      if(currentLandingPage){
        
        allProducts.filter((product) => {
          if(currentLandingPage.products.includes(product.productId)){
            if(!foundProduct && productKeyName === product.keyName){
              thisProduct = product;
              foundProduct = true;
            }
            theseProductNames.push(product.name);
          }
        });

        if(!foundProduct){
          allProducts.filter((product) => {
            if(currentLandingPage.products.includes(product.productId)){
              if(!foundProduct){
                thisProduct = product;
                foundProduct = true;
              }
            }
          });
        }
      

        setAllProductNames(theseProductNames);
        setProductKeyName(thisProduct.keyName);//???currentLandingPage
      }
      setProductId(thisProduct.productId);

      setCurrentProduct(thisProduct);

      setProductsReady(true);
    }
  }, [allProducts]);

  useEffect(() => {
    if(productsReady === true && landingPagesReady === true){
      setUpWidgets();
    }
  }, [productsReady, landingPagesReady]);

  useEffect(() => {
    setUpWidgets();
  }, [currentLandingPageWidgets, currentProduct, doReset, productKeyName]);


  function doUpdate(key, value){
    const newCards = cards.filter((x) => {
      if(x.title === key){
        x.verbiage = value;
        setDoReset(!doReset);
      }
      return x;
    });
    setCards(newCards);


    const newProducts = [];
    displayLandingPageProducts.filter((lpProduct) => {
      if(lpProduct.keyName !== productKeyName){
        newProducts.push(lpProduct);
      }
      else{
        lpProduct[key] = value;
        newProducts.push(lpProduct);
      }
    });
    setDisplayLandingPageProducts(newProducts);

  }

  function updateProductsArray(newProduct){
    if(!newProduct){
      return;
    }
    const newProducts = [];
    allProducts.filter((x) => {
      if(x.productId === newProduct.productId){
        newProducts.push(newProduct);
      }
      else{
        return newProducts.push(x);
      }
    });
    setAllProducts(newProducts);
  }

  function setUpWidgets(initProduct){
    if(!currentProduct && !initProduct){
      return;
    }
    setLoading(true);
    const thisProduct = initProduct ? initProduct : currentProduct;
    const allWidgets = thisProduct.widgets;//currentLandingPageWidgets;
    const newCards = [];
    setProductKeyName(thisProduct.keyName);

    allWidgets.map((widget, i) => {
      if(widget.type === 'text'){
        const thisWidget = thisProduct[widget.name];
        //const thisName = widget.name.charAt(0).toUpperCase() + widget.name.slice(1);
        const thisName = widget.name ? widget.name: i;

        newCards.push({id: thisName, title: thisName, verbiage: thisWidget, type: 'text'},);
      }

      if(widget.type === 'states'){
        const thisWidget = thisProduct[widget.name];
        //const thisName = widget.name.charAt(0).toUpperCase() + widget.name.slice(1);
        const thisName = widget.name ? widget.name: i;
        setStates(thisWidget);
        newCards.push({id: thisName, title: thisName, verbiage: thisWidget, type: 'jurisdiction'},);

      }

      if(widget.type === 'list'){
        const thisWidget = thisProduct[widget.name];
        //const thisName = widget.name.charAt(0).toUpperCase() + widget.name.slice(1);
        const thisName = widget.name ? widget.name: i;

        newCards.push({id: thisName, title: thisName, verbiage: thisWidget, type: 'list'},);
      }

      if(widget.type === 'search'){
        const thisWidget = thisProduct[widget.name];
        //const thisName = widget.name.charAt(0).toUpperCase() + widget.name.slice(1);
        const thisName = widget.name ? widget.name: i;

        newCards.push({id: thisName, title: thisName, verbiage: thisWidget, type: 'search'},);
      }
    });
    setCards(newCards);
    setCurrentProductName(thisProduct.name);
    setLoading(false);
  }

  function switchProduct(product){
    setLoading(true);
    setCurrentProduct(product);
    setProductKeyName(product.keyName);
    setProductId(product.productId);
    setUpWidgets(product);
    setDoReset(!doReset);
    setLoading(false);
    setStates(product.states);//currentProduct
  }

  async function handleCreate(name) {
    setLoading(true);
    setCreateDialogOpen(false);
    const resp = await ProductApi.create(name);
    
    setLoading(false);
  }

  function getProductsBar() {
    //this is not used right now, but I used it to create the initial products and landing pages, and we MAY want to ue it if we want to create more.
    return (
      <Container maxWidth={false} className={classes.container}>
        <Paper className={classes.paper}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                autoCorrect="off"
                spellCheck="false"
                label="Search"
                placeholder="Search"
                data-test="orgsFilterSearch"
                className={classes.searchText}
              />
            </Grid>
            <Grid item></Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCreateDialogOpen(true)}
              >
                Create Product
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCreateLandingPageDialogOpen(true)}
              >
                Create LandingPage
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

    

    const me = useContext(MeContext);
    return (
        <>
        <div className={classes.summaryBar} name={`landing-page-bar-${props.title}`} id={`landing-page-bar-${props.title}`}>
            Product: {currentLandingPageName}
        </div>
        {/*me && userUtil.isEmployee(me) && userUtil.isAdmin(me) && getProductsBar()//only for admins*/}
        <div className={classes.buttonBar} key={`button-bar-${props.title}`} name={`button-bar-${props.title}`} id={`button-bar-${props.title}`}>
          <Grid container spacing={2} alignItems="center" key={`product-grid-${props.title}`} name={`product-grid-${props.title}`} id={`product-grid-${props.title}`}>
            {displayLandingPageProducts && displayLandingPageProducts.length > 0 && displayLandingPageProducts.map((product, i) => {
                return (<Grid item key={i} name={`product-grid-i-${i}`} id={`product-grid-i-${i}`}>
                  <Button
                    variant="contained"
                    name={`product-button-${product.name}`}
                    key={`product-button-${product.name}`} 
                    id={`product-button-${product.name}`}
                    color={productKeyName === product.keyName ? "primary" : "#5B6770"}//"primary"
                    onClick={() => switchProduct(product)}
                  >
                    {product.name}
                  </Button>
                </Grid>);
              })
            }
            
          </Grid>
        </div>
        <div>
            <Grid container spacing={1} alignItems='center' key={`cards-top-grid-${props.title}`} name={`cards-top-grid-${props.title}`} id={`product-button-${props.title}`} >
                {!loading && singleResult === false && cards.map((card, i) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} className={classes.cardGrid} key={card.id} name={`card-grid-${i}`} id={`card-grid-${i}`}>
                            <SampleCard
                                key={card.id}
                                title={card.title}
                                verbiage={card.verbiage}
                                type={card.type}
                                doUpdate={doUpdate}
                                states={states}
                                landingPageId={landingPageId}
                                productId={productId}
                                doReset={doReset}
                                setDoReset={setDoReset}
                                productName={currentProductName}
                                loading={loading}
                                productKeyName={productKeyName}
                                setCurrentProduct={setCurrentProduct}
                                updateProductsArray={updateProductsArray}
                                updateSearches={updateSearches}
                                setUpdateSearches={setUpdateSearches}
                                i={i}
                                singleResult={singleResult}
                                setSingleResult={setSingleResult}
                                doInternalReset={doInternalReset}
                                setDoInternalReset={setDoInternalReset}
                            />
                    </Grid>
                ))}
                {!loading && singleResult !== false && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.cardGrid} key={cards[singleResult].id} name={`card-single-${cards[singleResult].title}`} id={`card-single-${cards[singleResult].title}`} >
                        <SampleCard
                            key={cards[singleResult].id}
                            title={cards[singleResult].title}
                            verbiage={cards[singleResult].verbiage}
                            type={cards[singleResult].type}
                            doUpdate={doUpdate}
                            states={states}
                            landingPageId={landingPageId}
                            productId={productId}
                            doReset={doReset}
                            setDoReset={setDoReset}
                            productName={currentProductName}
                            loading={loading}
                            productKeyName={productKeyName}
                            setCurrentProduct={setCurrentProduct}
                            updateProductsArray={updateProductsArray}
                            updateSearches={updateSearches}
                            setUpdateSearches={setUpdateSearches}
                            i={-1}
                            singleResult={singleResult}
                            setSingleResult={setSingleResult}
                            doInternalReset={doInternalReset}
                            setDoInternalReset={setDoInternalReset}
                        />
                </Grid>
                )}
            </Grid>
            {createDialogOpen && (
              <ProductDialog
                handleCreate={() => handleCreate}
                handleClose={() => setCreateDialogOpen(false)}
                name={`product-dialog`}
                id={`product-dialog`}
                key={`product-dialog`}
              ></ProductDialog>
            )}
             {createLandingPageDialogOpen && (
              <LandingPageDialog
                handleCreate={() => handleCreate}
                handleClose={() => setCreateDialogOpen(false)}
                name={`landing-page-dialog`}
                key={`landing-page-dialog`}
                id={`landing-page-dialog`}
              ></LandingPageDialog>
            )}
        </div>
        {loading && <LinearProgress></LinearProgress>} 
        </>
    );
}