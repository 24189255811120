// Packages
import React, { useState, useEffect, useContext } from "react";

// MUI Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import STATES from "enums/STATES";

import MeContext from "contexts/MeContext";
import * as userUtil from "util/userUtil";
import * as ProductApi from "apis/ProductApi";
import * as AssetApi from "apis/AssetApi";

// Styles
import { useStyles } from "./styles";

import Jurisdiction from "./jurisdiction";
import BrowseResults from "components/BrowseResults";
import LinearProgress from "@material-ui/core/LinearProgress";
import DisclaimerLanguageDialog from "components/DisclaimerLanguageDialog";
import DownloadMaterialsDialogV2 from "components/DownloadMaterialsDialogV2";
import DownloadDocumentDialogV2 from "components/DownloadDocumentDialogV2";


export default function SampleCard(props) {

    const me = useContext(MeContext);
    const isEmployee = userUtil.isEmployee(me);
    const isAdmin = userUtil.isAdmin(me);
    const { title, verbiage, type } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [statesToDisplayChip, setStatesToDisplayChip] = useState([]);
    const [finalStates, setFinalStates] = useState();
    const [tempStates, setTempStates] = useState();
    const [textToDisplay, setTextToDisplay] = useState(props.verbiage);
    const [loading, setLoading] = useState(false);
    const allStatesData = Object.values(STATES);
    const [newDataToSave, setNewDataToSave] = useState();
    const [currentProductName, setCurrentProductName] = useState();

    const [rerender, setRerender] = useState(false);


    const [drawerOpen, setDrawerOpen] = useState(false);
    const [results, setResults] = useState([]);
    const [line, setLine] = useState([]);
    const [doSingleDownload, setDoSingleDownload] = useState(false);
    const [showDisclaimerLanguageDialog, setShowDisclaimerLanguageDialog] = useState(false);
    const [showSingleDialog, setShowSingleDialog] = useState(false);
    const [filesArr, setFilesArr] = useState();
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [scrollId, setScrollId] = useState();
    const [productKeyName, setProductKeyName] = useState();
    const [resultsDict, setResultsDict] = useState({});
    const [scrollIdDict, setScrollIdDict] = useState({});
    const [showDialog, setShowDialog] = useState(false);

    const [packageType, setPackageType] = useState([]);
    const [packageTypeCategory, setPackageTypeCategory] = useState([]);
    const [snack, setSnack] = useState([]);
    

    function openDownloadDrawer(){
      console.log('openDownloadDrawer');
    }
    const handleRequestModal = () => {
      setShowRequestModal((prevState) => !prevState);
    };
    async function loadMore() {
      if (!scrollId) return;

      const tempResults = await AssetApi.scroll(scrollId);
      if (!tempResults?.hits?.hits?.length) {
        return;
      }

      setScrollId(tempResults._scroll_id); //store the scollId. it can change
      results.hits.hits = results.hits.hits.concat(tempResults.hits.hits);
      setResults(Object.assign({}, results));

      const newResultsDict = {...resultsDict};
      newResultsDict[props.productKeyName] = results;
      setResultsDict(newResultsDict);
      
    }
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setEditMode(false);
      
    };

    const handleEnlarge = () => {
      if(props.singleResult !== false || props.i === -1){
        props.setSingleResult(false);
        return;
      }
      props.setSingleResult(props.i);
    };

    const handleCancel = () => {
      
      setEditMode(false);
      if(type === 'jurisdiction'){
        setStatesToDisplayChip(tempStates);
      }
      else{
        setTextToDisplay(props.verbiage);
      }
      setAnchorEl(null);
      
    };
    const handleSave = async () => {
      let dataToSave = newDataToSave;
      if(type === 'jurisdiction'){

        
        let statesStr = '';
        statesToDisplayChip.filter((a) => {
          statesStr = statesStr + a.key + ',';
          return a.key;
        });
        if(statesStr.slice(-1) === ','){
          statesStr = statesStr.slice(0, -1); 
        }
        dataToSave = statesStr;
        
      }
      setAnchorEl(null);
      setEditMode(false);
      setLoading(true);
      const response = await ProductApi.update(props.productId, title, dataToSave);
      props.setCurrentProduct(response);
      props.updateProductsArray(response);
      props.doUpdate(title, dataToSave);
      if(type === 'jurisdiction'){
        //TODO: Update just the searches for this product.
        props.setUpdateSearches(!props.updateSearches);
        //window.location.reload(); 
        props.setDoInternalReset(!props.doInternalReset);
      }
      setLoading(false);
      
    };
    
    

    const handleAdminEdit = () => {
      if(!editMode){
        const tempA = statesToDisplayChip.filter((a) => {return a;});
        setTempStates(tempA);
      }
      setEditMode(true);
      setAnchorEl(null);
    };


    useEffect(() => {
      if(props.currentProductName && currentProductName !== props.currentProductName){
        setLoading(true);
        setTextToDisplay(props.verbiage);
        setCurrentProductName(props.currentProductName);
        setRerender(!rerender);
        setLoading(false);
      }
    }, [props.currentProductName]);

    
    useEffect(() => {
      if(props.updateSearches === true){
       delete resultsDict[props.productKeyName];
       delete scrollIdDict[props.productKeyName];
       props.setDoReset(true);
      }
    }, [props.updateSearches]);



    
    useEffect(() => {
      async function subFunction() {
        if(type === 'search'){
          await performSearch();
        }
      }
      setTextToDisplay(props.verbiage);
      subFunction();
    }, [props.verbiage, props.currentProductName, props.productId, props.productKeyName, props.doInternalReset]);

    useEffect(() => {
      setUpStates();
    }, [props.states, props.verbiage]);


    useEffect(() => {
        setProductKeyName(props.productKeyName);
        setLine([props.productKeyName]);
        if(resultsDict[props.productKeyName]){
          setResults(resultsDict[props.productKeyName]);
        }
        if(scrollIdDict[props.productKeyName]){
          setScrollId(scrollIdDict[props.productKeyName]);
        }
    }, [props.productKeyName]);


    
    

    function setUpStates(){
      const goodStates = [];
      if(type !== 'jurisdiction'){
        return;
      }
      if(!verbiage){
        return;
      }
      const itemsArray = verbiage.replace(/ /g, '').split(',');
      itemsArray.map((item) => {
        allStatesData.map( (itemB) => {
          if(itemB.key.toLowerCase() === item.toLowerCase()){
            goodStates.push(itemB);
          }
        });
        
      });
        
      if(goodStates.length === 0){
        goodStates.push({key:"N/A", name: "N/A"});
      }
      setStatesToDisplayChip(goodStates);

      const badStates = ['ALLSTATES', 'MULTISTATE', 'MU'];
      const dispStatesData = allStatesData.filter((item) => {
        return !badStates.includes(item.key);
      });
      const states = dispStatesData.sort((a,b) => {
        if(a.key.toLowerCase() > b.key.toLowerCase()){
          return 1;
        }
        if(a.key.toLowerCase() < b.key.toLowerCase()){
          return -1;
        }
        return 0;
      });

      if(states.length === 0){
        states.push('N/A');
      }
      setFinalStates(states);
    }
  
    async function performSearch() {
      if(!props.productKeyName){
        return;
      }
      if(resultsDict[props.productKeyName]){
        return;
      }
      if(type === 'search'){
        setLoading(true);
        setResults([]);
        const term = null;
        let filters = verbiage;
        try{
          filters = JSON.parse(verbiage);
        }
        catch(error){
          filters = verbiage;
        }
        if( props.productKeyName ){
          filters['productLine'] = [props.productKeyName];//[productKeyName];//[props.productKeyName];
          setLine([props.productKeyName]);
        }
        if( props.states ){
          try{
            filters['states'] = props.states.split(',');
            const foundNA = filters['states'].indexOf("N/A");
            if(foundNA !== -1){
              filters['states'].splice(foundNA, 1);
            }
            const foundUnDef = filters['states'].indexOf("undefined");
            if(foundUnDef !== -1){
              filters['states'].splice(foundUnDef, 1);
            }

            setPackageType(filters['publicationType']);
            setPackageTypeCategory(filters['publicationTypeCategory_query']);
          } catch(error){
            console.log('error: ', error);
          }
        }

        const tempResults = await AssetApi.search(term, filters);
        
        if(tempResults){
          setResults(tempResults);
          setScrollId(tempResults._scroll_id);
          const newResultsDict = {...resultsDict};
          const newScrollIdDict = {...scrollIdDict};

          newResultsDict[props.productKeyName] = tempResults;
          newScrollIdDict[props.productKeyName] = tempResults._scroll_id;
          setResultsDict(newResultsDict);
          setScrollIdDict(newScrollIdDict);
          props.setUpdateSearches(false);
          try{
            //TODO: DO WE WANT THIS? It will prevent loading more, but may prevent too many stored searches overloading ElasticSearch
            //AssetApi.scrollDelete(tempResults._scroll_id);
          }catch(error){
            console.log('error: ', error);
          }
        }
        setLoading(false);
      }
    }
      
    return (
        <React.Fragment>
          <Grid key={`card-grid-${props.title}`} name={`card-grid-${props.title}`} id={`card-grid-${props.title}`} item xs={12} className={classes.grid}>
            <Card className={classes.card} key={`card-${props.title}`} name={`card-${props.title}`} id={`card-${props.title}`}>
              <CardHeader
                id={`card-header-${props.title}`}
                name={`card-header-${props.title}`}
                key={`card-header-${props.title}`}
                className={classes.cardHeader}
                title={title.replace("_"," ")}
                action={
                  <IconButton aria-label="more" onClick={handleClick} id={`card-icon-${props.title}`}  name={`card-icon-${props.title}`} key={`card-icon-${props.title}`}>
                    <MoreVertIcon className={classes.moreVertIcon}/>
                  </IconButton>
                }
              />
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                name={`card-menu-${props.title}`}
                id={`card-menu-${props.title}`}
                key={`card-menu-${props.title}`}
              >
              
                  
                { !editMode && props.i !== -1 && (<MenuItem className={classes.menuItem} onClick={handleEnlarge}  key={`card-enlarge-${props.title}`} name={`card-enlarge-${props.title}`} id={`card-enlarge-${props.title}`} >Enlarge</MenuItem>) }
                { !editMode && props.i === -1 && (<MenuItem className={classes.menuItem} onClick={handleEnlarge}  key={`card-close-${props.title}`} name={`card-close-${props.title}`} id={`card-close-${props.title}`}>Close</MenuItem>) }
                { editMode && (<MenuItem className={classes.menuItem} onClick={handleSave}  key={`card-save-${props.title}`} name={`card-save-${props.title}`} id={`card-save-${props.title}`}>Save</MenuItem>) }

                {!editMode && isEmployee && isAdmin && (type !== 'search') && (
                  
                  <MenuItem className={classes.menuItem} onClick={handleAdminEdit} key={`card-edit-${props.title}`} name={`card-edit-${props.title}`} id={`card-edit-${props.title}`}>Edit</MenuItem>
                )}

                { editMode && isEmployee && isAdmin && (<MenuItem className={classes.menuItem} onClick={handleCancel} key={`card-close-${props.title}`} name={`card-close-${props.title}`} id={`card-close-${props.title}`}>Cancel</MenuItem>) }
                
                 
              </Menu>
              <CardContent className={props.i === -1 ? classes.singleCardContent : classes.cardContent} key={`card-content-${props.title}`} name={`card-content-${props.title}`} id={`card-content-${props.title}`}>
                { !props.loading && !loading && (<Paper className={classes.paper} elevation={0}>
                  <Typography className={classes.text}>
                    {type === 'jurisdiction' && !loading && (<Paper className={classes.chipsPaper} elevation={0}>
                      <Jurisdiction states={finalStates} setStates={setFinalStates} statesToDisplayChip={statesToDisplayChip} editMode={editMode} setStatesToDisplayChip={setStatesToDisplayChip} setAnchorEl={setAnchorEl} anchorEl={anchorEl}> </Jurisdiction>
                    </Paper>)}
                    { (type === 'text' || type === 'list' || (type === 'search' && editMode))  && !loading && (                      
                      <TextField
                          id={`textInput-outlined-multiline-flexible-${props.productId}-${props.title}`}
                          multiline
                          name={`textfield-${props.productName}-${props.title}`}
                          key={`textfield-${props.productName}-${props.title}`}
                          className={classes.cardContentText}
                          fullWidth
                          disabled={!editMode || (type === 'search')}
                          onChange={(e) => {
                            setNewDataToSave(e.target.value);
                            setTextToDisplay(e.target.value);
                          }}
                          value={textToDisplay}
                          inputProps={{style: { fontSize: '16px', color: 'black', fontFamily: 'Inter, sans-serif', fontWeight: 400, lineHeight: 1.5 }}} 
                        />
                      )}
                    { (type === 'search' ) && !editMode && results?.hits?.total?.value > 0 && !loading && (
                      <div className={classes.setLeft} key={`browse-results-${props.title}`} name={`browse-results-${props.title}`} id={`browse-results-${props.title}`} >
                        <BrowseResults
                          setDrawerOpen={setDrawerOpen}
                          handleRequestModal={handleRequestModal}
                          results={results}
                          line={line}
                          loading={loading}
                          loadMore={loadMore}
                          download={() => setShowDisclaimerLanguageDialog(true)}
                          isProductSelected={line.length > 0}
                          setFilesArr={setFilesArr}
                          setDoSingleDownload={setDoSingleDownload}
                          setShowDisclaimerLanguageDialog={setShowDisclaimerLanguageDialog}
                          setShowSingleDialog={setShowSingleDialog}
                          minDisplay={true}
                          
                        />
                        </div>
                      )}

                      { (type === 'search' ) && !editMode && results?.hits?.total?.value === 0 && !loading && (
                        <div className={classes.noResults} name={`no-results-${props.title}`} key={`no-results-${props.title}`} id={`no-results-${props.title}`}>
                          No results found
                        </div>
                      )}

                        {showDisclaimerLanguageDialog && (
                          <DisclaimerLanguageDialog
                            message="IF YOU ARE USING AAIS ADVISORY PRODUCTS OR SERVICES
                            AAIS IS REQUIRED TO REPORT TO STATE REGULATORS ANY USE OF ADVISORY CONTENT AND USE OF THESE MATERIALS MAY REQUIRE A GRANT OF FILING AUTHORIZATION.
                            PRIOR TO USING THIS CONTENT, PLEASE CONTACT AN AAIS ENGAGEMENT MANAGER AT MEMBERSHIP@AAISONLINE.COM TO DISCUSS YOUR USE OF ANY AAIS PRODUCTS AND SERVICES."
                            handleCancel={() => {
                              setShowDisclaimerLanguageDialog(false);
                            }}
                            handleAgree={() => {
                              setShowDisclaimerLanguageDialog(false);
                              if(doSingleDownload === true){
                                setShowSingleDialog(true);
                                setDoSingleDownload(false);
                              }
                              else{
                                setShowDialog(true);
                              }
                              
                            }}
                          />
                        )}
                        {showDialog && (
                          <DownloadMaterialsDialogV2
                            lineKey={line}
                            states={props.states.split(',')?.map((name) => STATES[name].key)}
                            packageTypes={packageType}
                            packageTypeCategory={packageTypeCategory}
                            imgClass_s={[]}
                            publicationTypeCategory_query={[]}
                            searchTerm={""}
                            excludeFileContent={'Y'}
                            handleClose={() => setShowDialog(false)}
                            setSnack={setSnack}
                            effectiveDate={null}
                            effectiveOldestDate={null}
                            plans={[]}
                            openDownloadDrawer={() => openDownloadDrawer()}
                          ></DownloadMaterialsDialogV2>
                        )}
                        {showSingleDialog && (
                          <DownloadDocumentDialogV2
                            filesArr={filesArr}
                            handleClose={() => setShowSingleDialog(false)}
                            setSnack={setSnack}
                          ></DownloadDocumentDialogV2>
                        )}
                        
                    
                  </Typography>
                </Paper>)}
                {loading && (<LinearProgress />)}
              </CardContent>
            </Card>
          </Grid>
        </React.Fragment>
      );
}